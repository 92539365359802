import type { DirectusUsers } from '~/types/directus.types'
import type {DropdownItem} from "#ui/types";

const { logout } = useDirectus()

export default () => {
  const me = useState<DirectusUsers | undefined>('me')

  async function logoutAction() {
    await logout()
    navigateTo('/login')
  }

  const userMenuItems: DropdownItem[][] = [
    [
      {
        label: `${me.value?.first_name} ${me.value?.last_name}`,
        slot: 'account',
        disabled: true,
      },
    ],
    [
      {
        label: 'Settings',
        icon: 'i-ph-gear',
        to: '/me',
      },
    ],
    [
      {
        label: 'Documentation',
        icon: 'i-ph-book-open',
      },
      {
        label: 'Changelog',
        icon: 'i-ph-megaphone',
      },
      {
        label: 'Status',
        icon: 'i-ph-broadcast',
      },
    ],
    [
      {
        label: 'Sign out',
        icon: 'i-ph-sign-out',
        click: logoutAction,
      },
    ],
  ]

  return {
    userMenuItems,
  }
}
