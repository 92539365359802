<script setup lang="ts">
import type {DirectusUsers, Seas} from "~/types/directus.types";

const { logout } = useDirectus()

const me = useState<DirectusUsers>('me')
const {userMenuItems} = useSailorLayout()

</script>

<template>
  <div class="min-h-screen flex flex-col">
    <header>
      <UContainer>
        <div class="flex my-4 justify-between gap-3">
          <div class="shadow border border-base-100 rounded-full flex items-center">
            <p class="px-4 py-1 truncate">
              Oceana
            </p>
          </div>
          <UDropdown :items="userMenuItems" :ui="{ item: { disabled: 'cursor-text select-text' } }" :popper="{ placement: 'bottom-start' }">
            <UAvatar size="lg" :src="me.avatar ? useAsset(me.avatar as string) : null" :alt="`${me?.first_name} ${me?.last_name}`" class="shadow-lg" />

            <template #account="{ item }">
              <div class="text-left">
                <p>
                  Signed in as
                </p>
                <p class="truncate font-medium text-gray-900 dark:text-white">
                  {{ item.label }}
                </p>
              </div>
            </template>


          </UDropdown>
        </div>
      </UContainer>
    </header>
    <div class="flex-1 py-8">
      <UContainer>
        <slot />
      </UContainer>
    </div>
    <footer class="footer p-8 bg-base-100 text-neutral-content">
      <div class="flex items-center gap-4 justify-center w-full">
        <div>
          Oceana
        </div>
        <div>
          <NuxtLink to="/privacy">
            Privacy
          </NuxtLink>
        </div>
        <div>
          <NuxtLink to="/imprint">
            Imprint
          </NuxtLink>
        </div>
        <div>
          &copy; {{ (new Date()).getFullYear() }}
        </div>
      </div>
    </footer>
  </div>
</template>
